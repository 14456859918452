<template>
  <svg class="svg" :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z" fill="#00BF4A"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6981 9.81295C10.8902 9.53003 10.9281 9.32636 10.7433 8.93047C10.4691 8.34236 9.50511 6.93335 8.88498 6.7129C8.59861 6.61106 8.31206 6.67904 7.97283 6.87895C6.97379 7.4674 6.61523 8.35312 6.67254 9.48483C6.86619 13.3179 10.6858 17.1332 14.5161 17.3268C15.6472 17.3842 16.5323 17.0254 17.1206 16.0258C17.3204 15.6864 17.3883 15.3998 17.2863 15.1132C17.0662 14.4928 15.658 13.5283 15.0702 13.2539C14.6743 13.0691 14.4709 13.1069 14.1881 13.2991C13.2337 13.9488 13.2454 14.7884 12.0811 14.0346C11.2079 13.4693 10.5279 12.7945 9.96307 11.9211C9.20968 10.7562 10.0489 10.7678 10.6981 9.81295Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'accept-call-icon',
  props: {
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 24
    }
  }
}
</script>
