export const DEFAULT_STATE = {
  filter: {},
  tags: [],
  campaigns: [],
  users: [],
  ringGroups: [],
  workflows: [],
  changelogs: [],
  dispositionStatuses: [],
  callDispositions: [],
  scripts: [],
  templates: [],
  broadcasts: [],
  filters: [],
  firstLogin: false,
  userStatus: false,
  oldAgentStatus: false,
  dialer: {
    token: null,
    call: null,
    isReady: false,
    currentStatus: null,
    currentNumber: null,
    communication: null,
    contact: null,
    onSpeaker: false,
    isMuted: false,
    isHeld: false,
    recordingStatus: 'in-progress',
    timer: '',
    wrapUpTimer: '',
    parkedCallTimer: '',
    duration: 0,
    wrapUpDuration: '',
    parkedCallDuration: 0,
    parkedCall: null,
    dealId: null,
    callFishing: {
      communication: null,
      contact: null
    },
    error: {
      message: '',
      code: null
    }
  },
  warnings: [],
  shouldIntroduce: false,
  addedParty: null,
  keyboard: {
    scroll: null,
    resizeMode: null
  },
  currentInputDevice: 'default',
  inputDevices: [],
  currentOutputDevice: 'default',
  outputDevices: [],
  notifications: {
    system: {
      title: '',
      message: '',
      messageIcon: null,
      dateTime: null,
      contactId: '',
      communicationId: '',
      campaignId: '',
      campaignName: '',
      ringGroupName: '',
      phoneNumber: '',
      communication: null,
      contact: null,
      queue: null
    },
    sms: {
      title: '',
      message: '',
      messageIcon: null,
      dateTime: null,
      contactId: '',
      communicationId: '',
      campaignId: '',
      campaignName: '',
      ringGroupName: '',
      phoneNumber: '',
      communication: null,
      contact: null,
      queue: null
    },
    call: {
      title: '',
      message: '',
      messageIcon: null,
      dateTime: null,
      contactId: '',
      communicationId: '',
      campaignId: '',
      campaignName: '',
      ringGroupName: '',
      phoneNumber: '',
      communication: null,
      contact: null,
      queue: null
    },
    callVoicemail: {
      title: '',
      message: '',
      messageIcon: null,
      dateTime: null,
      contactId: '',
      communicationId: '',
      campaignId: '',
      campaignName: '',
      ringGroupName: '',
      phoneNumber: '',
      communication: null,
      contact: null,
      queue: null
    },
    voicemail: {
      title: '',
      message: '',
      messageIcon: null,
      dateTime: null,
      contactId: '',
      communicationId: '',
      campaignId: '',
      campaignName: '',
      ringGroupName: '',
      phoneNumber: '',
      communication: null,
      contact: null,
      queue: null
    },
    mention: {
      title: '',
      message: '',
      messageIcon: null,
      dateTime: null,
      contactId: '',
      communicationId: '',
      campaignId: '',
      campaignName: '',
      ringGroupName: '',
      phoneNumber: '',
      communication: null,
      contact: null,
      queue: null
    },
    incomingCall: {
      title: '',
      message: '',
      messageIcon: null,
      dateTime: null,
      contactId: '',
      communicationId: '',
      campaignId: '',
      campaignName: '',
      ringGroupName: '',
      phoneNumber: '',
      communication: null,
      contact: null,
      queue: null
    },
    callFishing: {
      title: '',
      message: '',
      messageIcon: null,
      dateTime: null,
      contactId: '',
      communicationId: '',
      campaignId: '',
      campaignName: '',
      ringGroupName: '',
      phoneNumber: '',
      communication: null,
      contact: null,
      queue: null
    }
  },
  showIncomingCallNotification: false,
  // cached states
  sidebarFolded: false,
  currentCompany: null,
  smsTemplates: [],
  tagOptions: {
    isReset: false
  },
  tagsFullyLoaded: false,
  prevRoute: null,
  currentRoute: null,
  breadcrumbs: {
    crumbs: '',
    name: ''
  },
  dialerFormStatus: false,
  isMobile: false,
  isTabletOrMobile: false,
  contactDetailsDrawer: false,
  showPhone: false,
  enableAudio: false,
  callFishingQueue: [],
  communicationNotifiedDesktop: [],
  voicemailNotifiedDesktop: [],
  contactNotifiedDesktop: [],
  appointmentNotifiedDesktop: [],
  reminderNotifiedDesktop: [],
  defaultDateFilter: null,
  sessionPhoneExpansion: '',
  notificationAudio: null,
  fishingModeNotificationAudio: null,
  loadingParkedCalls: false,
  parkedCalls: [],
  suspended: false,
  showProFeatureDialog: false,
  leadSources: [],
  statics: {
    domain: null,
    favicon: null,
    host: null,
    logo: null,
    logo_inverse: null,
    logo_square: null,
    logo_square_inverse: null,
    name: null,
    path: null,
    referer: null,
    whitelabel: false
  },
  staticsLoaded: false,
  isCallDisposed: false,
  isContactDisposed: false,
  isWhiteLabel: false,
  isDatatableSelectedAll: false,
  isDatatableCountLoading: false
}
