export const DEFAULT_STATE = {
  paths: [
    'cache',
    'auth.profile',
    'contacts.lists',
    'contacts.showMyContacts',
    'contacts.isShortenedUrlRemembered',
    'powerDialer.sessionSettings',
    'powerDialer.selectedList',
    'powerDialer.ongoingSession',
    'powerDialer.sessionCallStatuses',
    'powerDialer.countdownTimer',
    'powerDialer.isSessionRunning',
    'powerDialer.redialed',
    'inbox.inboxShowMyContacts'
  ]
}
